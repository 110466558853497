import { h } from "preact";
import { useCallback, useEffect, useRef, useState } from "preact/hooks";
import dayjs from "dayjs";
import clsx from "clsx";
import { serializeError } from "serialize-error";
import lowerCase from "lodash/lowerCase";
import kebabCase from "lodash/kebabCase";

import { Overlay } from "@components/Overlay";
import { ErrorText, Text } from "@components/Text";
import {
    frameMeta,
    frameHandler,
    errorHandler,
    ALS_WIDGET_ID_HEADER,
    ALS_COMPANY_ID_HEADER,
    ALS_ANALYTICS_ID,
    analytics,
} from "@host";
import { useFrameListener } from "@hooks/use-frame-listener";
import { useProductRouter } from "@hooks/use-product-router";
import { rootApiClient } from "@api/api-client";
import { useRootModels, IDealerState } from "@store";
// import { escapeForRegex } from "@util/regex";

import { initialData, query, hostUrl, referrerUrl } from "@host";

import CloseIcon from "@assets/close-icon.svg";
import styles from "./index.module.scss";

import type { CommandHandler } from "@host/frame";
import type { ClientLaunchContext, ContextDatalayer } from "@shared/types";
import type { NextComponentType } from "next";
import { getField } from "@util/config";

export const config = {
    unstable_runtimeJS: false,
};

const Launch: NextComponentType = () => {
    const router = useProductRouter(true);
    const navigated = useRef(false);
    const launching = useRef(false);
    const widgetDetailsReq = useRef<Promise<IDealerState["widget"]> | null>(null);

    const {
        dealer: {
            get: getDealerState,
            merge: mergeDealerState,
            update: updateDealer,
        },
        datalayer: {
            dispatch: {
                setDatalayer,
            },
        },
    } = useRootModels();
    const [error, setError] = useState("");

    const getWidgetDetails = useCallback(async (
        cid?: string,
        context = {} as ClientLaunchContext,
    ) => {
        const dealerState = getDealerState();
        const doLogError = (
            err: string | Error,
            extra = {} as Record<string, unknown>,
        ) => {
            errorHandler.logError(err, {
                dealer: dealerState,
                frame: initialData,
                ...extra,
            });
        };
        // if (!hostUrl) {
        //     const msg = `The "u" parameter must be set to the current page's URL`;
        //     doLogError(msg);
        //     return setError(msg);
        // }

        // if (referrerUrl.hostname) {
        //     const matchReferrerTld = new RegExp(escapeForRegex(referrerUrl.hostname) + "$", "i");
        //     const matchHostUrlTld = new RegExp(escapeForRegex(hostUrl.hostname) + "$", "i");
        //     const isValidHostUrl = (
        //         hostUrl.hostname.match(matchReferrerTld)
        //         || referrerUrl.hostname.match(matchHostUrlTld)
        //     );

        //     if (!isValidHostUrl) {
        //         const msg = `The specified source domain "${hostUrl.hostname}" cannot be used on "${referrerUrl.hostname}"`;
        //         doLogError(msg);
        //         return setError(msg);
        //     }
        // }

        let curWidgetState = dealerState.widget;
        let variant = context.variant || initialData.variant || context.theme || "";

        if (!context.variant && initialData.variant) {
            context.variant = initialData.variant;
        }

        if (variant) {
            variant = kebabCase(context.variant).toLowerCase();
        }

        if (
            dealerState.companyId !== cid
            || dealerState.ctaId !== context.ctaId
            || dealerState.variant !== variant
        ) {
            widgetDetailsReq.current = null;
            curWidgetState = undefined;
        }

        setDatalayer(context.datalayer);

        if (!process.browser || curWidgetState) {
            return widgetDetailsReq.current = Promise.resolve(curWidgetState);
        }

        // Don't requery the backend if there is currently a request in flight
        if (widgetDetailsReq.current) return widgetDetailsReq.current;

        if (!dealerState.widgetId) {
            setError("Invalid Widget ID!");
            return;
        }

        mergeDealerState({
            companyId: cid,
            ctaId: context.ctaId,
            variant,
        });

        const addHeader = (headerName: string, headerValue?: string) => {
            if (!headerName || !headerValue) return;
            const apiHeaders = rootApiClient.ApiClient.headers;
            if (!apiHeaders.has(headerName)) {
                apiHeaders.append(headerName, headerValue);
            }
        };

        const startTime = dayjs();
        try {
            addHeader(ALS_WIDGET_ID_HEADER, dealerState.widgetId);
            addHeader(ALS_COMPANY_ID_HEADER, cid);
            addHeader(ALS_ANALYTICS_ID, analytics.getClientIds());

            return await (widgetDetailsReq.current = rootApiClient.getWidgetDetails({
                widget: dealerState.widgetId,
                ctaId: context.ctaId,
                variant,
            })
                .then(({ getWidgetDetails }) => {
                    if (RUNTIME_DEBUG) console.log("Widget Details:", getWidgetDetails);

                    const primaryColor = getField(getWidgetDetails.fields, "primaryColor");
                    const secondaryColor = getField(getWidgetDetails.fields, "secondaryColor");
                    const buttonBg = getField(getWidgetDetails.fields, "buttonBg");
                    const language = getField(getWidgetDetails.fields, "language");

                    if (primaryColor) {
                        updateDealer.primaryColor(primaryColor);
                    }
                    if (secondaryColor) {
                        updateDealer.secondaryColor(secondaryColor);
                    }
                    if (buttonBg) {
                        updateDealer.buttonBg(buttonBg);
                    }
                    if (language) {
                        const lang = lowerCase(language);
                        updateDealer.language(lang);
                    }

                    updateDealer.widget(getWidgetDetails);
                    return getWidgetDetails;
                })
            );
        } catch (err) {
            widgetDetailsReq.current = null;

            const api = new URL(API_URL);
            const health = await fetch(`${api.origin}/health`, { method: "GET" })
                .then(async (res) => {
                    if (res.ok) return res.json();
                    return [res.statusText, await res.text()].filter(Boolean).join(": ");
                })
                .catch((err) => {
                    return serializeError(err);
                });
            const endTime = dayjs();
            const timeDiff = dayjs(endTime.diff(startTime)).utc().format("HH:mm:ss.SSS");
            doLogError(err, {
                timeTaken: timeDiff,
                health,
            });
            setError(err.message);
        }
    }, [getDealerState, mergeDealerState, setDatalayer, updateDealer]);

    const launchHandler: CommandHandler<"launch"> = useCallback(async ({
        feature,
        product,
        context,
    }) => {
        if (launching.current) return;
        if (!product && feature) product = feature;
        if (RUNTIME_DEBUG) console.log("launchHandler:", product, context);

        setError("");
        launching.current = true;
        const widgetDetails = await getWidgetDetails(context.company || frameHandler.company, context);

        if (widgetDetails && !navigated.current) {
            router.navigateTo(product, context, {
                track: true,
            });
            navigated.current = true;
        }
        frameHandler.productBuffered(false);
        launching.current = false;
    }, [router, getWidgetDetails]);

    useFrameListener("launch", launchHandler, () => (
        !frameMeta.productTargeted && !frameMeta.embedded
    ));

    const immediateChecked = useRef(false);
    useEffect(() => {
        // console.log("Launch Mounted");
        const shouldLaunchImmediately = !immediateChecked.current && !!(
            frameMeta.embedded ||
            frameMeta.productTargeted ||
            frameHandler.isProductBuffered
        );

        if (shouldLaunchImmediately) {
            launchHandler(frameHandler.selectedProduct!);
        }

        immediateChecked.current = true;
    }, [router, launchHandler]);

    return (
        <Overlay fillHeightEmbedded>
            <div className={styles.loaderContainer}>
                <div class={clsx(
                    styles.loader,
                    error && styles.error,
                )}>
                    <div
                        className={styles.close}
                    // This gets set by the script imported below it
                    // onClick={() => {
                    //     frameHandler.send("hide");
                    // }}
                    >
                        <CloseIcon />
                    </div>
                    <script dangerouslySetInnerHTML={{
                        __html: require("./index.client?inline"),
                        // __html: "",
                    }} />
                    {!error ? (
                        <div class={styles.spinner} />
                    ) : (
                        <div class={styles.errorMessage}>
                            <p>
                                <Text>Unable to load application!</Text >
                            </p>
                            <p>
                                <ErrorText>{error}</ErrorText>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </Overlay>
    );
};

export default Launch;